<template>
    <div>
      <div
        class="modal right fade"
        :id="idModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog report-modal">
          <div class="modal-content">
            <div class="card-modal">
              <div class="modal-header">
                <h5 class="modal-title">Parameter Laporan</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body mt-3">
                <div class="modal-sub-title">Periode</div>
                <hr />
  
                <div class="form-group d-flex align-items-center">
                  <label class="form-label">Periode </label>
                  <div class="row w-100">
                    <div class="col-12">
                    <Datepicker
                      :autoApply="true"
                      v-model="formModal.date"
                      selectText="Pilih"
                      cancelText="Batal"
                      class="w-100"
                      :enableTimePicker="false"
                      :maxDate="new Date()"
                      :format="format"
                      monthPicker
                      placeholder="Periode"
                      @update:modelValue="dateReport($event, 'date')"
                    ></Datepicker>
                    </div>
                  </div>
                </div>
                <div class="modal-sub-title">Parameter Tambahan</div>
                <hr />
  
                <div
                  class="form-group d-flex align-items-center mb-3"
                  v-if="isLedger"
                >
                  <label class="form-label">Akun Perkiraan</label>
                  <Select2
                    style="width: 100%"
                    v-model="formModal.id_akun"
                    :options="optionAkun"
                    placeholder="Pilih Akun Perkiraan"
                    :settings="{
                      allowClear: true,
                      templateResult: formatState,
                      multiple: true,
                    }"
                  />
                </div>
  
                <div class="form-group d-flex align-items-center">
                  <label class="form-label">Departemen</label>
                  <Select2
                    style="width: 100%"
                    v-model="formModal.id_divisi"
                    :options="optionDepartement"
                    placeholder="Pilih Departement"
                    @change="mySelectEvent()"
                    @select="mySelectEvent()"
                    :settings="{
                      allowClear: true,
                    }"
                  />
                </div>
                <div
                  class="form-group"
                  v-if="isMergeValue && !formModal.id_divisi"
                >
                  <div class="clearfix">
                    <label for="form-label flaot-left"
                      >Gabungkan Nilai Akun Perkiraan Departemen</label
                    >
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          v-model="formModal.isAll"
                          :checked="formModal.isAll"
                          :value="true"
                          class="custom-control-input"
                          :id="`customyearall`"
                          @change="changeCheckbox('all')"
                        />
                        <label class="custom-control-label" :for="`customyearall`"
                          >Pilih Semua</label
                        >
                      </div>
                    </div>
                    <div
                      class="col-12"
                      v-for="(value, key) in optionDepartement"
                      :key="key"
                    >
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          v-model="formModal.merge"
                          :checked="
                            formModal.merge.includes(value.id) ||
                            formModal.merge.includes(value.id.toString())
                          "
                          @change="changeCheckbox('item')"
                          :value="value.id"
                          class="custom-control-input"
                          :id="`custom-${value.id}`"
                        />
                        <label
                          class="custom-control-label"
                          :for="`custom-${value.id}`"
                          >{{ value.text }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="form-group" v-if="isOther">
                  <div class="row">
                    <div class="col-12">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          v-model="formModal.isOther['show-zero']"
                          :checked="formModal.isOther['show-zero']"
                          :value="true"
                          class="custom-control-input"
                          :id="`showzerorange`"
                        />
                        <label class="custom-control-label" :for="`showzerorange`"
                          >Tampilkan data dengan nilai 0</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-save"
                  style="width: unset"
                  @click="showReport"
                >
                  Tampilkan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import $ from "jquery";
  import { v4 as uuid4 } from "uuid";
  import { cksClient } from "../../../../helper";
  import Select2 from "vue3-select2-component";
  import moment from "moment";
  import "moment/moment";
  import "moment/locale/id";
  import { maska } from "maska";
  import Datepicker from "vue3-date-time-picker";
  export default {
    name: "modalSingleMonth",
    props: {
      show: Boolean,
      label: String,
      type: String,
      onPage: Boolean,
      departement: Object,
      parameter: Object,
      isMerge: Boolean,
      isLedger: Boolean,
      akun: Object,
    },
  
    directives: {
      maska,
    },
  
    components: {
      Select2,
      Datepicker
    },
  
    data() {
      return {
        idModal: uuid4(),
        idPicker: uuid4(),
        optionDepartement: this.$props.departement,
        optionAkun: this.$props.akun,
        accessDepartement: cksClient().get("_account").akses_departement,
        isMergeValue: this.$props.isMerge ? this.$props.isMerge : false,
        optionYear: [],
        formModal: {
          id_divisi:
            this.$props.parameter && this.$props.parameter.id_divisi
              ? this.$props.parameter.id_divisi
              : "",
          date:
            this.$props.parameter && this.$props.parameter.date
              ? this.$props.parameter.date
              : moment().format("YYYY-MM"),
          merge:
            this.$props.parameter && this.$props.parameter.merge
              ? this.$props.parameter.merge
              : [],
          isAll:
            this.$props.parameter && this.$props.parameter.isAll
              ? this.$props.parameter.isAll
              : false,
          id_akun:
            this.$props.parameter && this.$props.parameter.id_akun
              ? this.$props.parameter.id_akun
              : [],
          isOther:
            this.$props.parameter && this.$props.parameter.isOther
              ? this.$props.parameter.isOther
              : [],
        },
      };
    },
  
    created() {
    },
  
    methods: {
        format(date, format = "MMMM YYYY") {
      if (date && date.year && parseInt(date.month) >= 0) {
        var string = date.year + "-" + (date.month + 1) + "-01";
        return `${moment(string).format(format)}`;
      }
    },
      showModal() {
        // this.formModal.year = "";
        if (this.accessDepartement && !this.formModal.id_divisi) {
          var data = this.accessDepartement.split("|");
          this.formModal.id_divisi = data[0];
        } else {
          // this.formModal.id_divisi = "";
        }
        $("#" + this.idModal).modal("show");
        $("#" + this.idModal).on("hidden.bs.modal", () => {
          this.$emit("hide", false);
        });
      },
      dateReport(data, key) {
        this.formModal[key] = data;
      },
      setHide() {
        this.showModalRange = false;
      },
      showReport() {
        // console.log(this.formModal.year, this.formModal.year.length);
        if (this.formModal.date && this.formModal.date.month) {
          $("#" + this.idModal).modal("hide");
          if (!this.$props.onPage) {
            var type = this.$props.type;
            console.log(type)
            var data = {...this.formModal}
            data.date = this.formModal.date.year+'-'+(this.formModal.date.month + 1)
            if (type == "ratio_monthly") {
              $('[data-toggle="tooltip"]').tooltip("hide");
              this.$router.push({
                name: "RatioMonthly",
                query: data,
              });
            }
          } else {
            this.$emit("update", this.formModal);
          }
        }
      },
      mySelectEvent() {},
  
      changeCheckbox(type = "all") {
        if (type != "all") {
          var lengthAll = this.optionDepartement.length;
          var lengthMerge = this.formModal.merge.length;
          if (lengthAll > 0 && lengthAll == lengthMerge) {
            this.formModal.isAll = true;
          } else {
            this.formModal.isAll = false;
          }
        } else {
          this.formModal.merge = [];
          if (this.formModal.isAll) {
            for (const key in this.optionDepartement) {
              if (Object.hasOwnProperty.call(this.optionDepartement, key)) {
                const element = this.optionDepartement[key];
                this.formModal.merge.push(element.id);
              }
            }
          }
        }
      },
    },
  
    watch: {
      show: function (newVal) {
        if (newVal) {
          setTimeout(() => {
            this.showModal();
          }, 500);
        }
      },
      departement: function (newVal) {
        this.optionDepartement = newVal;
      },
      akun: function (newVal) {
        this.optionAkun = newVal;
      },
      parameter: function (newVal) {
        this.formModal.id_divisi = newVal.id_divisi ? newVal.id_divisi : "";
        this.formModal.year = newVal.year ? newVal.year : "";
        this.formModal.merge = newVal.isMerge ? newVal.isMerge : [];
        this.formModal.isOther = newVal.isOther ? newVal.isOther : [];
        this.formModal.isAll = newVal.isAll ? newVal.isAll : false;
        this.formModal.id_akun = newVal.id_akun ? newVal.id_akun : "";
      },
      isMerge: function (newVal) {
        this.isMergeValue = newVal;
      },
    },
  };
  </script>
  
  <style scoped src="../../../../assets/css/style.css"></style>
  
  <style scoped src="../../../../assets/css/custom.css"></style>
  
  <style scoped>
  .text :nth-child(1) {
    font-size: 12px;
    font-weight: 500;
    color: #000;
  }
  
  #modalProfitLoss .modal-dialog {
    max-width: 700px;
  }
  
  #modalBalance .modal-dialog {
    max-width: 633px;
  }
  
  .text {
    margin-left: 10px;
  }
  
  .text :nth-child(2) {
    font-size: 10px;
    color: #898989;
  }
  
  .modal-sub-title {
    font-size: 15px;
    font-weight: 600;
  }
  
  .form-group .control-label,
  .form-group > label {
    font-weight: 500;
  }
  
  .checkbox-text {
    font-weight: 500;
    font-size: 13px;
  }
  </style>
  