import { API } from "../../../constans/Url";
import { apiClient } from "../../../helper";

const get_ReportRatio = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.RATIO.GET, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


export {
  get_ReportRatio,
}